import jwt_decode from "jwt-decode";
import constants from "@/constants";

/**
 * Return if user is logged in
 * This is completely up to you and how you want to store the token in your frontend application
 * e.g. If you are using cookies to store the application please update this function
 */
// eslint-disable-next-line arrow-body-style
export function isUserLoggedIn() {
  const accessToken = localStorage.getItem("accessToken");
  if (accessToken) {
    const exp = jwt_decode(accessToken).exp;

    if (new Date(Date.now()) < new Date(exp * 1000)) {
      return true;
    }
  }
  logOut();
  return false;
}

export const getUserData = () => {
  let user = JSON.parse(localStorage.getItem("user"));
  if (user && Object.keys(user).length !== 0 && user._id) {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: "gtm.load",
      userId: user._id,
      orgId: constants.orgId,
    });
  }
  return user;
};

export const getAccessToken = () => localStorage.getItem("accessToken");

export const logOut = () => {
  // for persisting the user selective theme
  // var theme = localStorage.getItem("theme");
  localStorage.clear();
  // localStorage.setItem("theme", theme);
};

/**
 * This function is used for demo purpose route navigation
 * In real app you won't need this function because your app will navigate to same route for each users regardless of ability
 * Please note role field is just for showing purpose it's not used by anything in frontend
 * We are checking role just for ease
 * NOTE: If you have different pages to navigate based on user ability then this function can be useful. However, you need to update it.
 * @param {String} userRole Role of user
 */
export const getHomeRouteForLoggedInUser = (userRole) => {
  if (userRole === "admin") return "/";
  if (userRole === "client") return { name: "access-control" };
  return { name: "auth-login" };
};
