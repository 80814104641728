import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);
const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
  routes: [
    {
      path: "/",
      name: "home",
      component: () => import("@/views/Home.vue"),
      meta: {
        pageTitle: "Home",
        breadcrumb: [
          {
            text: "Home",
            active: true,
          },
        ],
        layout: "full",
      },
    },
    {
      path: "/timephantom",
      name: "Time Phantom",
      component: () => import("@/views/timephantom/Main.vue"),
      meta: {
        layout: "full",
        pageTitle: "",
        breadcrumb: [
          {
            text: "",
            active: true,
          },
        ],
      },
    },
    {
      path: "/quiz",
      name: "Quiz",
      component: () => import("@/views/quiz/Main.vue"),
      meta: {
        layout: "full",
        pageTitle: "",
        breadcrumb: [
          {
            text: "",
            active: true,
          },
        ],
      },
    },
    {
      path: "/activate_alvox",
      name: "Activate Alvox",
      component: () => import("@/views/alvox_activation/Main.vue"),
      meta: {
        layout: "full",
        pageTitle: "",
        breadcrumb: [
          {
            text: "",
            active: true,
          },
        ],
      },
    },
  ],
});

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById("loading-bg");
  if (appLoading) {
    appLoading.style.display = "none";
  }
});

export default router;
