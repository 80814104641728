export default {
  SET_CURRENT_QUIZ_INDEX(state, value) {
    state.currentQuizIndex = value;
  },
  PUSH_CONVERSATION_DATA(state, value) {
    state.conversationData.push(value);
  },
  SET_USER_RESPONSE(state, value) {
    state.conversationData.push(value);
  },
  SET_QUIZ_ID(state, value) {
    state.quizId = value;
  },
  SET_ITEM_ID(state, value) {
    state.itemId = value;
  },
  SET_QUIZ_DATA(state, value) {
    state.quizData = value;
  },
  SET_QUIZ_TYPE(state, value) {
    state.validationType = value;
  },
  SET_QUIZ_STATUS(state, value) {
    state.quizCompleted = value;
  },
  SET_COURSE_DETAILS(state, value) {
    state.courseDetails = value;
  },
};
