export default {
  token: "",
  userId: "",
  name: "",
  email: "",
  orgSlug: "",
  timezone: "",
  organizationId: "",
  orgAlexImage: "",
};
