import axios from "@/libs/axios";
export default {
  async submitResponseToQuiz(_, payload) {
    const headers = {
      "Content-Type": "application/json",
      Authorization: this.state.user.token,
    };
    const res = await axios.post(`https://api-core-gateway.getboarded.com/evaluation/quiz/question/response`, payload, {
      headers,
    });
    return res.data.data;
  },


  async submitUploadQuiz(_, payload) {
    const headers = {
      "Content-Type": "application/json",
      Authorization: this.state.user.token,
    };
    const res = await axios.post(`https://api-core-gateway.getboarded.com/evaluation/document`, payload, {
      headers,
    });
    return res;
  },

  async getCompleteQuiz() {
    const headers = {
      "Content-Type": "application/json",
      Authorization: this.state.user.token,
    };
    const quiz = await axios.get(
      `https://api-core-gateway.getboarded.com/evaluation/quiz/${this.state.user.organizationId}/${this.state.quiz.quizId}`,
      { headers }
    );
    return quiz.data;
  },

  async getQuizStatus() {
    const headers = {
      "Content-Type": "application/json",
      Authorization: this.state.user.token,
    };
    const quiz = await axios.get(
      `https://api-core-gateway.getboarded.com/evaluation/quiz/result/${this.state.user.organizationId}/${this.state.user.userId}/${this.state.quiz.quizId}`,
      { headers }
    );
    return quiz.data.data.quizComplete;
  },

  async getFeedItemDetails(_, payload) {
    const res = await axios.get(`https://feed.getboarded.tech/getitemdetails?itemId=${payload.itemId}&type=${payload.type}`);
    return res.data;
  },
};
