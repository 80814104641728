export default {
  SET_USER(state, value) {
    state.userId = value;
  },
  SET_EMAIL(state, value) {
    state.email = value;
  },
  SET_NAME(state, value) {
    state.name = value;
  },
  SET_TOKEN(state, value) {
    state.token = value;
  },
  SET_ORGANIZATION(state, value) {
    state.organizationId = value;
  },
  SET_ORGANIZATION_SLUG(state, value) {
    state.orgSlug = value;
  },
  SET_TIMEZONE(state, value) {
    state.timezone = value;
  },
  SET_ORGANIZATION_ALEX_IMAGE_PATH(state, value) {
    state.orgAlexImage = value;
  },
};
