import state from './moduleBotState'
import mutations from './moduleBotMutations'
import actions from './moduleBotActions'
import getters from './moduleBotGetters'

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}
