import axios from "@/libs/axios";
export default {
  async updateAlexpreferences(_, payload) {
    const headers = { "Content-Type": "application/json" };
    const res = await axios.post(
      `https://api-core-gateway.getboarded.com/alex/digest?gtbrddUserId=${this.state.user.userId}`,
      payload,
      { headers }
    );
    return res.data.data;
  },

  async getCompleteAnswer(_, payload) {
    const headers = {
      "Content-Type": "application/json",
      Authorization: "Basic 529f1f07351c36002032eeb12bfde826",
    };
    const res = await axios.post(`https://agent.getboarded.com/api/chatbot/comp-answer`, payload, { headers });
    // const res = await axios.post(`http://localhost:7500/api/chatbot/comp-answer`, payload, { headers });
    return res.data;
  },

  async getALexDigest(_, token) {
    const headers = { "Content-Type": "application/json", Authorization: token };
    const res = await axios.get(`https://api-core-gateway.getboarded.com/alex/digest?gtbrddUserId=${this.state.user.userId}`, {
      headers,
    });
    return res.data.data;
  },
};
